<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("PAYMENT_METHODS.PAYMENT_METHODS_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openPaymentMethodCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_PAYMENT_METHODS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("PAYMENT_METHODS.ADD_PAYMENT_METHOD") }}
              </span>
            </base-button>

            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_PAYMENT_METHODS)"
              :objectType="'paymentMethods'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <payment-method-list-table
        :holder-type="holderType"
        :holder-id="holderId"
        @onViewPaymentMethod="openPaymentMethodViewModal"
        @onEditPaymentMethod="openPaymentMethodEditModal"
        @onDeletePaymentMethod="deletePaymentMethod"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewPaymentMethodModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewPaymentMethodModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PAYMENT_METHOD_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PAYMENT_METHODS.VIEW_PAYMENT_METHOD") }}
              </h1>
            </div>

            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openPaymentMethod"
                :objectType="'paymentMethods'"
                :objectId="openPaymentMethod.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button class="close" @click="closePaymentMethodModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-payment-method-component
              v-if="openPaymentMethod"
              :paymentMethodId="openPaymentMethod.id"
              @onEditPaymentMethod="openPaymentMethodEditModal"
              @onDeletePaymentMethod="deletePaymentMethod"
              @onSetAsDefault="setAsDefault"
              @onActivateOrDeactivate="activateOrDeactivate"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditPaymentMethodModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditPaymentMethodModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PAYMENT_METHOD'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PAYMENT_METHODS.EDIT_PAYMENT_METHOD") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openPaymentMethodViewModal(openPaymentMethod)"
                >
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closePaymentMethodModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-payment-method-component
              v-if="openPaymentMethod"
              :paymentMethodId="openPaymentMethod.id"
              @onViewPaymentMethod="openPaymentMethodViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddPaymentMethodModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddPaymentMethodModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PAYMENT_METHOD'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PAYMENT_METHODS.ADD_PAYMENT_METHOD") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closePaymentMethodModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-payment-method-component
              :holder-type="holderType"
              :holder-id="holderId"
              @onViewPaymentMethod="openPaymentMethodViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PaymentMethodListTable from "./partials/PaymentMethodListTable.vue";
import EditPaymentMethodComponent from "./components/EditPaymentMethodComponent.vue";
import AddPaymentMethodComponent from "./components/AddPaymentMethodComponent.vue";
import ViewPaymentMethodComponent from "./components/ViewPaymentMethodComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PaymentMethodListTable,
    NotificationSubscription,
    EditPaymentMethodComponent,
    AddPaymentMethodComponent,
    ViewPaymentMethodComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  props: [
    "holderType",
    "holderId",
    "holderName",
  ],

  data() {
    const paymentMethodId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewPaymentMethodModalOpened = false;
    let isEditPaymentMethodModalOpened = false;
    let isAddPaymentMethodModalOpened = false;
    let openPaymentMethod = null;
    if (paymentMethodId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPaymentMethodModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPaymentMethodModalOpened = true;
      }
      openPaymentMethod = { id: paymentMethodId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPaymentMethodModalOpened = true;
    }
    return {
      isViewPaymentMethodModalOpened: isViewPaymentMethodModalOpened,
      isEditPaymentMethodModalOpened: isEditPaymentMethodModalOpened,
      isAddPaymentMethodModalOpened: isAddPaymentMethodModalOpened,
      openPaymentMethod: openPaymentMethod,
      renderKey: 1,
    };
  },

  methods: {
    openPaymentMethodCreateModal() {
      this.closePaymentMethodModal();
      this.isAddPaymentMethodModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PaymentMethods",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openPaymentMethodViewModal(paymentMethod, reRender = false) {
      this.closePaymentMethodModal();
      this.openPaymentMethod = paymentMethod;
      this.isViewPaymentMethodModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PaymentMethods",
          query: { id: this.openPaymentMethod.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openPaymentMethodEditModal(paymentMethod) {
      this.closePaymentMethodModal();
      this.openPaymentMethod = paymentMethod;
      this.isEditPaymentMethodModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PaymentMethods",
          query: { id: this.openPaymentMethod.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closePaymentMethodModal() {
      this.isAddPaymentMethodModalOpened = false;
      this.isViewPaymentMethodModalOpened = false;
      this.isEditPaymentMethodModalOpened = false;
      this.openPaymentMethod = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PaymentMethods",
          query: {},
        }).href
      );
    },
    async setAsDefault(paymentMethod) {
        const confirmation = await swal.fire({
          text: this.$t("PAYMENT_METHODS.SET_THIS_PAYMENT_METHOD_AS_DEFAULT"),
          type: "question",
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonText:
            this.$t("COMMON.YES") + ", " + this.$t("PAYMENT_METHODS.SET_AS_DEFAULT"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        try {
          if (confirmation.value === true) {
            await this.$store.dispatch(
              "paymentMethods/setAsDefault",
              paymentMethod
            );
            this.renderKey++;
            this.$notify({
              type: "success",
              message: this.$t("PAYMENT_METHODS.PAYMENT_METHOD_SETED_AS_DEFAULT"),
            });
          }
        } catch (error) {
          console.log(error);
          await this.showRequestError(error);
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      },

      async activateOrDeactivate(paymentMethod) {
        const confirmation = await swal.fire({
          text: paymentMethod.is_active ? this.$t("PAYMENT_METHODS.DEACTIVATE_PAYMENT_METHOD") : this.$t("PAYMENT_METHODS.ACTIVATE_PAYMENT_METHOD"),
          type: "question",
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonText:
            this.$t("COMMON.YES") + ", " + (paymentMethod.is_active ? this.$t("PAYMENT_METHODS.DEACTIVATE") : this.$t("PAYMENT_METHODS.ACTIVATE")),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        try {
          if (confirmation.value === true) {
            await this.$store.dispatch(
              "paymentMethods/activateOrDeactivate",
              paymentMethod
            );
            this.renderKey++;
            this.$notify({
              type: "success",
              message: this.$t("PAYMENT_METHODS.PAYMENT_METHOD_ACTIVATION_CHANGED"),
            });
          }
        } catch (error) {
          console.log(error);
          await this.showRequestError(error);
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      },

    async deletePaymentMethod(paymentMethod) {
      const confirmation = await swal.fire({
        text: this.$t("PAYMENT_METHODS.DELETE_THIS_PAYMENT_METHOD"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "paymentMethods/destroy",
            paymentMethod.id
          );
          this.renderKey++;
          this.closePaymentMethodModal();
          this.$notify({
            type: "success",
            message: this.$t("PAYMENT_METHODS.PAYMENT_METHOD_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
