<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <base-input
                v-if="
                  $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
                "
                  :placeholder="$t('COMMON.ORGANIZATION')"
                >
                  <organization-selector
                    :filterable="true"
                    :showAll="true"
                    @organizationChanged="
                      (organizationId) => {
                        this.selectedOrganizationId = organizationId;
                      }
                    "
                  />
                </base-input>
        <base-input
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          :placeholder="`${$t('COMMON.LOCATION')}`"
        >
          <location-selector
            :filterable="true"
            :showAll="true"
            :organization="selectedOrganizationId"
            @locationChanged="
              (locationId) => {
                this.selectedLocationId = locationId
              }
            "
          />
        </base-input>
        <base-input>
          <el-select
            :label="$t('PAYMENT_METHODS.STATUS')"
            :placeholder="$t('PAYMENT_METHODS.STATUS')"
            v-model="selectedStatut"
            @change="
              (status) => {
                selectedStatut = status;
              }
            "
          >
            <el-option
              v-for="(value, key) in PAYMENT_METHODS_STATUS_LIST"
              :key="key"
              :value="value"
              :label="value ? $t(`PAYMENT_METHODS.${value}`) : ''"
            />
          </el-select>
        </base-input>
        <base-input>
          <el-select
            :label="$t('COMMON.TYPE')"
            :placeholder="$t('COMMON.TYPE')"
            v-model="selectedType"
            @change="
              (type) => {
                selectedType = type;
              }
            "
          >
            <el-option
              v-for="(value, key) in PAYMENT_METHODS_TYPES"
              :key="key"
              :value="value"
              :label="value ? $t(`PAYMENT_METHODS.${value}`) : ''"
            />
          </el-select>
        </base-input>

        <base-input
          v-model="query"
          type="search"
          prepend-icon="far fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />

        <base-button
          class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm mb-4"
          icon
          size="sm"
          @click="resetFilters"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-undo"></i>
          </span>
        </base-button>
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="paymentMethods"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>

            <!-- <el-table-column
              :label="$t('PAYMENT_METHODS.NUMERO')"
              prop="elixio_token"
              sortable="custom"
              min-width="150"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.elixio_token ?? "" }}
                </span>
              </template>
            </el-table-column> -->

            <el-table-column
              :label="$t('PAYMENT_METHODS.DETAILS')"
              prop="status"
              min-width="250"
            >
              <template v-slot="{ row }">
                {{ row.details.account_number ?? "" }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('PAYMENT_METHODS.PAYMENT_METHOD_HOLDER')"
              prop="holder"
              min-width="200"
            >
              <template v-slot="{ row }">
                {{ row.details.holder ?? "" }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('PAYMENT_METHODS.TYPE')"
              prop="type"
              sortable="custom"
              min-width="100"
            >
              <template v-slot="{ row }">
                <span>
                  {{ $t(`PAYMENT_METHODS.${row.payment_method_type}`) }}
                </span>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('PAYMENT_METHODS.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_PAYMENT_METHODS)"
                >
                  <a
                    type="text"
                    @click="viewPaymentMethod(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
                <!-- <el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                  v-if="!row.is_default && $currentUserCan($permissions.PERM_EDIT_PAYMENT_METHODS)"
                >
                  <a
                    disabled
                    type="text"
                    @click="editPaymentMethod(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip> -->
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  v-if="
                    !row.is_default && $currentUserCan($permissions.PERM_DELETE_PAYMENT_METHODS)
                  "
                >
                  <a
                    type="text"
                    @click="deletePaymentMethod(row.id)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import { PAYMENT_METHODS_STATUS_LIST, PAYMENT_METHODS_TYPES } from "@/constants/paymentMethods";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";

export default {
  name: "payment-method-list-table",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    OrganizationSelector,
    LocationSelector
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      holderType:null,
      holderId:null,
      selectedStatut: null,
      selectedType: null,
      selectedOrganizationId: null,
      selectedLocationId:null,
      PAYMENT_METHODS_STATUS_LIST:PAYMENT_METHODS_STATUS_LIST,
      PAYMENT_METHODS_TYPES:PAYMENT_METHODS_TYPES,
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      paymentMethods: [],
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    selectedStatut: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedType: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganizationId: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocationId: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {

    resetFilters() {
      this.query = null;
      this.selectedStatut = null;
      this.selectedType = null;
      this.selectedOrganizationId = null;
      this.selectedLocationId = null;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      if(this.selectedLocationId){
        this.holderType = 'Location';
        this.holderId = this.selectedLocationId;
      }else if(this.selectedOrganizationId){
        this.holderType = 'Organization';
        this.holderId = this.selectedOrganizationId;
      } 
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
            ...(this.selectedStatut ? { status: this.selectedStatut } : {}),
            ...(this.selectedType ? { payment_method_type: this.selectedType } : {}),
            ...(this.holderType ? { holder_type: this.holderType } : {}),
            ...(this.holderId ? { holder_id: this.holderId } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        };

        await this.$store.dispatch("paymentMethods/list", params);
        this.paymentMethods = this.$store.getters["paymentMethods/list"];
        this.total = this.$store.getters["paymentMethods/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editPaymentMethod(paymentMethod) {
      this.$emit("onEditPaymentMethod", paymentMethod);
    },

    async deletePaymentMethod(id) {
      const confirmation = await swal.fire({
        text: this.$t("PAYMENT_METHODS.DELETE_THIS_PAYMENT_METHOD"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("paymentMethods/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("PAYMENT_METHODS.PAYMENT_METHOD_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewPaymentMethod(paymentMethod) {
      this.$emit("onViewPaymentMethod", paymentMethod);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
